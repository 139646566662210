exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awardee-alumni-featured-alumni-js": () => import("./../../../src/pages/awardee/alumni/featured-alumni.js" /* webpackChunkName: "component---src-pages-awardee-alumni-featured-alumni-js" */),
  "component---src-pages-awardee-alumni-index-js": () => import("./../../../src/pages/awardee/alumni/index.js" /* webpackChunkName: "component---src-pages-awardee-alumni-index-js" */),
  "component---src-pages-awardee-kontribusi-js": () => import("./../../../src/pages/awardee/kontribusi.js" /* webpackChunkName: "component---src-pages-awardee-kontribusi-js" */),
  "component---src-pages-awardee-penerima-beasiswa-panduan-js": () => import("./../../../src/pages/awardee/penerima-beasiswa/panduan.js" /* webpackChunkName: "component---src-pages-awardee-penerima-beasiswa-panduan-js" */),
  "component---src-pages-awardee-pengayaan-bahasa-kebijakan-js": () => import("./../../../src/pages/awardee/pengayaan-bahasa/kebijakan.js" /* webpackChunkName: "component---src-pages-awardee-pengayaan-bahasa-kebijakan-js" */),
  "component---src-pages-awardee-persiapan-keberangkatan-angkatan-js": () => import("./../../../src/pages/awardee/persiapan-keberangkatan/angkatan.js" /* webpackChunkName: "component---src-pages-awardee-persiapan-keberangkatan-angkatan-js" */),
  "component---src-pages-awardee-persiapan-keberangkatan-kebijakan-js": () => import("./../../../src/pages/awardee/persiapan-keberangkatan/kebijakan.js" /* webpackChunkName: "component---src-pages-awardee-persiapan-keberangkatan-kebijakan-js" */),
  "component---src-pages-beasiswa-kebijakan-umum-js": () => import("./../../../src/pages/beasiswa/kebijakan-umum.js" /* webpackChunkName: "component---src-pages-beasiswa-kebijakan-umum-js" */),
  "component---src-pages-beasiswa-pendaftaran-beasiswa-js": () => import("./../../../src/pages/beasiswa/pendaftaran-beasiswa.js" /* webpackChunkName: "component---src-pages-beasiswa-pendaftaran-beasiswa-js" */),
  "component---src-pages-beasiswa-serba-serbi-index-js": () => import("./../../../src/pages/beasiswa/serba-serbi/index.js" /* webpackChunkName: "component---src-pages-beasiswa-serba-serbi-index-js" */),
  "component---src-pages-beasiswa-serba-serbi-single-js": () => import("./../../../src/pages/beasiswa/serba-serbi/single.js" /* webpackChunkName: "component---src-pages-beasiswa-serba-serbi-single-js" */),
  "component---src-pages-beasiswa-single-js": () => import("./../../../src/pages/beasiswa/single.js" /* webpackChunkName: "component---src-pages-beasiswa-single-js" */),
  "component---src-pages-grantees-daftar-grantees-js": () => import("./../../../src/pages/grantees/daftar-grantees.js" /* webpackChunkName: "component---src-pages-grantees-daftar-grantees-js" */),
  "component---src-pages-grantees-kisah-inspiratif-rispro-js": () => import("./../../../src/pages/grantees/kisah-inspiratif-rispro.js" /* webpackChunkName: "component---src-pages-grantees-kisah-inspiratif-rispro-js" */),
  "component---src-pages-grantees-rispro-tech-brief-js": () => import("./../../../src/pages/grantees/rispro-tech-brief.js" /* webpackChunkName: "component---src-pages-grantees-rispro-tech-brief-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informasi-berita-index-js": () => import("./../../../src/pages/informasi/berita/index.js" /* webpackChunkName: "component---src-pages-informasi-berita-index-js" */),
  "component---src-pages-informasi-berita-single-js": () => import("./../../../src/pages/informasi/berita/single.js" /* webpackChunkName: "component---src-pages-informasi-berita-single-js" */),
  "component---src-pages-informasi-hubungi-kami-js": () => import("./../../../src/pages/informasi/hubungi-kami.js" /* webpackChunkName: "component---src-pages-informasi-hubungi-kami-js" */),
  "component---src-pages-informasi-infografik-js": () => import("./../../../src/pages/informasi/infografik.js" /* webpackChunkName: "component---src-pages-informasi-infografik-js" */),
  "component---src-pages-informasi-informasi-publik-js": () => import("./../../../src/pages/informasi/informasi-publik.js" /* webpackChunkName: "component---src-pages-informasi-informasi-publik-js" */),
  "component---src-pages-informasi-karir-index-js": () => import("./../../../src/pages/informasi/karir/index.js" /* webpackChunkName: "component---src-pages-informasi-karir-index-js" */),
  "component---src-pages-informasi-karir-single-js": () => import("./../../../src/pages/informasi/karir/single.js" /* webpackChunkName: "component---src-pages-informasi-karir-single-js" */),
  "component---src-pages-informasi-laporan-keuangan-js": () => import("./../../../src/pages/informasi/laporan-keuangan.js" /* webpackChunkName: "component---src-pages-informasi-laporan-keuangan-js" */),
  "component---src-pages-informasi-laporan-kinerja-js": () => import("./../../../src/pages/informasi/laporan-kinerja.js" /* webpackChunkName: "component---src-pages-informasi-laporan-kinerja-js" */),
  "component---src-pages-informasi-laporan-lain-js": () => import("./../../../src/pages/informasi/laporan-lain.js" /* webpackChunkName: "component---src-pages-informasi-laporan-lain-js" */),
  "component---src-pages-informasi-laporan-tahunan-js": () => import("./../../../src/pages/informasi/laporan-tahunan.js" /* webpackChunkName: "component---src-pages-informasi-laporan-tahunan-js" */),
  "component---src-pages-informasi-live-js": () => import("./../../../src/pages/informasi/live.js" /* webpackChunkName: "component---src-pages-informasi-live-js" */),
  "component---src-pages-investasi-dana-abadi-js": () => import("./../../../src/pages/investasi/dana-abadi.js" /* webpackChunkName: "component---src-pages-investasi-dana-abadi-js" */),
  "component---src-pages-investasi-instrumen-portfolio-js": () => import("./../../../src/pages/investasi/instrumen-portfolio.js" /* webpackChunkName: "component---src-pages-investasi-instrumen-portfolio-js" */),
  "component---src-pages-investasi-skema-kebijakan-js": () => import("./../../../src/pages/investasi/skema-kebijakan.js" /* webpackChunkName: "component---src-pages-investasi-skema-kebijakan-js" */),
  "component---src-pages-kompetisi-bisnis-daftar-tahap-1-js": () => import("./../../../src/pages/kompetisi-bisnis/daftar/tahap-1.js" /* webpackChunkName: "component---src-pages-kompetisi-bisnis-daftar-tahap-1-js" */),
  "component---src-pages-kompetisi-bisnis-daftar-tahap-2-js": () => import("./../../../src/pages/kompetisi-bisnis/daftar/tahap-2.js" /* webpackChunkName: "component---src-pages-kompetisi-bisnis-daftar-tahap-2-js" */),
  "component---src-pages-kompetisi-bisnis-daftar-tahap-3-js": () => import("./../../../src/pages/kompetisi-bisnis/daftar/tahap-3.js" /* webpackChunkName: "component---src-pages-kompetisi-bisnis-daftar-tahap-3-js" */),
  "component---src-pages-kompetisi-bisnis-daftar-tahap-4-js": () => import("./../../../src/pages/kompetisi-bisnis/daftar/tahap-4.js" /* webpackChunkName: "component---src-pages-kompetisi-bisnis-daftar-tahap-4-js" */),
  "component---src-pages-kompetisi-bisnis-daftar-tahap-5-js": () => import("./../../../src/pages/kompetisi-bisnis/daftar/tahap-5.js" /* webpackChunkName: "component---src-pages-kompetisi-bisnis-daftar-tahap-5-js" */),
  "component---src-pages-peta-situs-js": () => import("./../../../src/pages/peta-situs.js" /* webpackChunkName: "component---src-pages-peta-situs-js" */),
  "component---src-pages-prasyarat-js": () => import("./../../../src/pages/prasyarat.js" /* webpackChunkName: "component---src-pages-prasyarat-js" */),
  "component---src-pages-riset-kebijakan-rispros-umum-js": () => import("./../../../src/pages/riset/kebijakan-rispros-umum.js" /* webpackChunkName: "component---src-pages-riset-kebijakan-rispros-umum-js" */),
  "component---src-pages-riset-pendaftaran-penjadwalan-js": () => import("./../../../src/pages/riset/pendaftaran-penjadwalan.js" /* webpackChunkName: "component---src-pages-riset-pendaftaran-penjadwalan-js" */),
  "component---src-pages-riset-single-js": () => import("./../../../src/pages/riset/single.js" /* webpackChunkName: "component---src-pages-riset-single-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-tentang-manajemen-dewan-pengawas-js": () => import("./../../../src/pages/tentang/manajemen/dewan-pengawas.js" /* webpackChunkName: "component---src-pages-tentang-manajemen-dewan-pengawas-js" */),
  "component---src-pages-tentang-manajemen-dewan-penyantun-js": () => import("./../../../src/pages/tentang/manajemen/dewan-penyantun.js" /* webpackChunkName: "component---src-pages-tentang-manajemen-dewan-penyantun-js" */),
  "component---src-pages-tentang-manajemen-direksi-js": () => import("./../../../src/pages/tentang/manajemen/direksi.js" /* webpackChunkName: "component---src-pages-tentang-manajemen-direksi-js" */),
  "component---src-pages-tentang-penghargaan-js": () => import("./../../../src/pages/tentang/penghargaan.js" /* webpackChunkName: "component---src-pages-tentang-penghargaan-js" */),
  "component---src-pages-tentang-selayang-pandang-js": () => import("./../../../src/pages/tentang/selayang-pandang.js" /* webpackChunkName: "component---src-pages-tentang-selayang-pandang-js" */),
  "component---src-pages-tentang-struktur-organisasi-js": () => import("./../../../src/pages/tentang/struktur-organisasi.js" /* webpackChunkName: "component---src-pages-tentang-struktur-organisasi-js" */),
  "component---src-pages-tentang-testimoni-js": () => import("./../../../src/pages/tentang/testimoni.js" /* webpackChunkName: "component---src-pages-tentang-testimoni-js" */),
  "component---src-pages-tentang-visi-misi-js": () => import("./../../../src/pages/tentang/visi-misi.js" /* webpackChunkName: "component---src-pages-tentang-visi-misi-js" */)
}

